import React, { useEffect, useState } from 'react'
import Layout from '../components/layouts'
import { Footer } from '../components/shareable/footer/Footer'
import { Flex, ImageContain, Text } from '../components/primitives'
import concept1 from '../images/hemset-koncept1.png'
import concept2 from '../images/hemset-koncept2.png'
import concept3 from '../images/hemset-koncept3.png'
import concept4 from '../images/hemset-koncept4.png'
import concept5 from '../images/hemset-koncept5.png'
import concept6 from '../images/hemset-koncept6.png'


import { WhatYouGetMenu } from '../components/shareable/navbar/WhatYouGetMenu'
import { BlogMenu } from '../components/shareable/navbar/BlogMenu'
import { HeadingMedium } from '../components/shareable/text/HeadingMedium'

const getConcept = (hash) => {
  if (hash === 'one') {
    return concept1
  } else if (hash === 'two') {
    return concept2
  } else if (hash === 'three') {
    return concept3
  } else if (hash === 'four') {
    return concept4
  } else if (hash === 'five') {
    return concept5
  } else if (hash === 'six') {
    return concept6
  } 
}


export default ({ data, navigate, location }) => {
  const [hash, setHash] = useState('one')
  const [modalIsOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (location.hash) {
      setHash(location.hash.replace('#', ''))
    }
  }, [location.hash])

  console.log(hash)
  return (
    <Layout
      blackNav={true}
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}
      >
      <Flex minHeight={'100vh'} width={'100%'}>
        {/*        <HeadingMedium mt={'100px'} mb={10} title={'Blogg'} />*/}

        <Flex
          width={'100%'}
          mt={['60px', '60px', '80px']}
          jusifyContent={'center'}
          flexDirection={['column', 'column', 'row']}>
          <Flex
            width={['100%', '100%', '250px']}
            flexDirection={'column'}
            alignItems={'center'}>
            <HeadingMedium mb={6} title={'Vad du får'} />

            <Text textAlign={'center'} pl={2} pr={2} mb={6}>
              Se hur utvalda inredningskoncept levereras till våra kunder. Genom
              att fortsätta scrolla guidas du igenom konceptet där du får en
              beskrivning, moodboard, planskiss och en digital shoppinglista med alla
              produkter som är framtagna och anpassade efter kundens behov och
              önskemål.
            </Text>
            <WhatYouGetMenu />
          </Flex>
          <Flex
            mb={'50px'}
            flexDirection={'column'}
            maxWidth={'1000px'}
            width={['100%', '100%', '80%']}>
            <ImageContain src={getConcept(hash)} />
          </Flex>
        </Flex>
      </Flex>

      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
