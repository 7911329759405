import React from 'react'
import { Flex } from '../../primitives'
import { Link } from 'gatsby'
import { ButtonSelect } from '../buttons/ButtonSelect'

export const BlogMenu = () => {
  return (
    <Flex
      flexDirection={'column'}
      alignItems={['center', 'center', 'center']}
      mb={4}
      width={['100%', '100%', '250px']}>
      <Link to={'/blog/#tip'} style={{ textDecoration: 'none' }}>
        <ButtonSelect mb={2}>INREDAREN TIPSAR</ButtonSelect>
      </Link>
      <Link to={'/blog/#customer'} style={{ textDecoration: 'none' }}>
        <ButtonSelect mb={2}>KUNDEN BERÄTTAR</ButtonSelect>
      </Link>
      <Link to={'/blog/#service'} style={{ textDecoration: 'none' }}>
        <ButtonSelect mb={2}>OM TJÄNSTEN</ButtonSelect>
      </Link>
      <Link to={'/blog/#decorator'} style={{ textDecoration: 'none' }}>
        <ButtonSelect mb={2}>VÅRA INREDARE</ButtonSelect>
      </Link>
      <Link to={'/blog/#friends'} style={{ textDecoration: 'none' }}>
        <ButtonSelect mb={2}>VÅRA VÄNNER</ButtonSelect>
      </Link>
      <Link to={'/blog/#inspo'} style={{ textDecoration: 'none' }}>
        <ButtonSelect mb={2}>INSPIRATION</ButtonSelect>
      </Link>
    </Flex>
  )
}
