import styled from 'styled-components'
import { Button } from '../../primitives'
import { colors } from '../../../other/colors/color'

const ButtonSelect = styled(Button)`
  border: ${(props) => props.border};
  &:hover {
    background-color: ${colors.primaryLight};
    color: ${colors.white};
  }
  &:focus {
    outline: none;
  }
`

ButtonSelect.defaultProps = {
  bg: 'transparent',
  color: 'primaryLight',
  border: '1px solid rgba(201, 180, 158, 1)'
}

export { ButtonSelect }
