import React from 'react'
import { Flex, Text } from '../../primitives'
import { Link } from 'gatsby'
import { ButtonSelect } from '../buttons/ButtonSelect'

export const WhatYouGetMenu = () => {
  return (
    <Flex
      flexDirection={'column'}
      alignItems={['center', 'center', 'center']}
      mb={4}
      width={['100%', '100%', '250px']}>
      <Flex flexDirection={'column'}>
        <Text mb={2}>TYP AV RUM</Text>

        <Link to={'/what/#one'} style={{ textDecoration: 'none' }}>
          <ButtonSelect mb={2}>VARDARGSRUM 15 000 kr</ButtonSelect>
        </Link>
        <Link to={'/what/#two'} style={{ textDecoration: 'none' }}>
          <ButtonSelect mb={2}>VARDARGSRUM 25 000 kr</ButtonSelect>
        </Link>
        <Link to={'/what/#three'} style={{ textDecoration: 'none' }}>
          <ButtonSelect mb={2}>VARDARGSRUM 40 000 kr</ButtonSelect>
        </Link>
        <Link to={'/what/#four'} style={{ textDecoration: 'none' }}>
          <ButtonSelect mb={2}>VARDARGSRUM 40 000 kr</ButtonSelect>
        </Link>
        <Link to={'/what/#five'} style={{ textDecoration: 'none' }}>
          <ButtonSelect mb={2}>VARDARGSRUM 80 000 kr</ButtonSelect>
        </Link>
        <Link to={'/what/#six'} style={{ textDecoration: 'none' }}>
          <ButtonSelect mb={2} minWidth = {212} >SOVRUM 12 000 kr</ButtonSelect>
        </Link>
      </Flex>
    </Flex>
  )
}
